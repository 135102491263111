const SocialKakao = () => {
  const Rest_api_key = "a019d87781b3ea781cf9137f9f29af1c"; //REST API KEY
  const redirect_uri = "http://localhost:3000/auth/kakao"; //Redirect URI
  // oauth 요청 URL
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;
  const handlekakaoLogin = () => {
    window.location.href = kakaoURL;
  };
  return (
    <>
      <button type="button" className="kakao" onClick={handlekakaoLogin}>
        <img
          width="22px"
          src="https://connectv-s3.s3.ap-northeast-2.amazonaws.com/connectValue/assets/images/login%2Bjoin-page/kakao.png"
          alt="kakao"
          className="kakao_icon"
        />
        <span>카카오톡 로그인</span>
      </button>
    </>
  );
};
export default SocialKakao;
