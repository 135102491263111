import React, { useState } from "react";
import axios from "axios";
import SocialKakaoSignUp from "./component/kakaosignup.js";

function SignUpForm() {
  const [formData, setFormData] = useState({
    username: "",
    userId: "",
    email: "",
    password: "",
    phoneNumber: "",
  });

  const [isAgreed, setIsAgreed] = useState(false);
  const [cvAgreed, setCvAgreed] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.username) {
      alert("이름을 입력해주세요.");
      return;
    } else if (!formData.userId) {
      alert("아이디를 입력해주세요.");
      return;
    } else if (!formData.phoneNumber) {
      alert("핸드폰 번호를 입력해주세요.");
      return;
    } else if (!formData.email) {
      alert("이메일을 입력해주세요.");
      return;
    } else if (!formData.password) {
      alert("패스워드를 입력해주세요.");
      return;
    } else if (!isAgreed) {
      alert("개인정보 수집 및 이용동의에 체크해주세요.");
      return;
    } else if (!cvAgreed) {
      alert("커넥트밸류 이용약관에 체크해주세요.");
      return;
    }
    try {
      const response = await axios.post(
        "https://dev-api.connectplay.kr:9443/api/users/signup",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      if (error.response) {
        // 서버로부터의 응답이 있는 경우
        console.error("Signup failed:", error.response.data);
        alert(error.response.data.message);
      } else if (error.request) {
        // 요청은 이루어졌으나 응답을 받지 못한 경우
        console.error("No response received:", error.request);
        alert("서버로부터 응답을 받지 못했습니다.");
      } else {
        // 요청 설정 중에 발생한 오류
        console.error("Error:", error.message);
        alert("요청 중 오류가 발생했습니다: " + error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="login-form signup-form">
      <div>
        {" "}
        <div className="sign_text">회원가입</div>
        <label htmlFor="username">이름</label>
        <input
          id="username"
          name="username"
          type="text"
          placeholder="이름 입력"
          value={formData.username}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="username">아이디</label>
        <input
          id="userId"
          name="userId"
          type="text"
          placeholder="아이디 입력"
          value={formData.userId}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="phoneNumber">핸드폰 번호</label>
        <input
          id="phoneNumber"
          name="phoneNumber"
          type="number"
          placeholder="- 제외한 숫자만 입력"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="email">이메일</label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="email@email.com"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="password">비밀번호</label>
        <input
          id="password"
          name="password"
          type="password"
          placeholder="비밀번호 입력"
          value={formData.password}
          onChange={handleChange}
        />
      </div>
      <div className="agreement-container">
        <input
          className="agreement-checkbox"
          id="agreement_individual"
          name="agreement_individual"
          type="checkbox"
          checked={isAgreed}
          onChange={(e) => setIsAgreed(e.target.checked)}
        />
        <label className="agreement" htmlFor="agreement">
          [필수] 개인정보 수집 및 이용동의
        </label>
      </div>
      <div className="agreement-container">
        <input
          className="agreement-checkbox"
          id="agreement_cv"
          name="agreement_cv"
          type="checkbox"
          checked={cvAgreed}
          onChange={(e) => setCvAgreed(e.target.checked)}
        />
        <label className="agreement" htmlFor="agreement">
          [필수] 커넥트밸류 이용약관
        </label>
      </div>
      <button type="submit">회원가입</button>
      <SocialKakaoSignUp />
      {/* <button type="button" className="kakao">
        <img
          width="22px"
          src="https://connectv-s3.s3.ap-northeast-2.amazonaws.com/connectValue/assets/images/login%2Bjoin-page/kakao.png"
          alt="kakao"
          className="kakao_icon"
        />
        <span>카카오톡 가입하기</span>
      </button> */}
    </form>
  );
}

export default SignUpForm;
