const SocialKakaoSignUp = () => {
  const Rest_api_key = "a019d87781b3ea781cf9137f9f29af1c"; // REST API KEY
  const redirect_uri = "http://localhost:3000/auth/kakao"; // Redirect URI for signup
  const kakaoSignUpURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

  const handleKakaoSignUp = () => {
    console.log("카카오 가입하기 클릭");
    window.location.href = kakaoSignUpURL;
  };

  return (
    <button type="button" className="kakao" onClick={handleKakaoSignUp}>
      <img
        width="22px"
        src="https://connectv-s3.s3.ap-northeast-2.amazonaws.com/connectValue/assets/images/login%2Bjoin-page/kakao.png"
        alt="kakao"
        className="kakao_icon"
      />
      <span>카카오톡 가입하기</span>
    </button>
  );
};

export default SocialKakaoSignUp;
