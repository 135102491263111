import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css/LoginForm.css";
import SocialKakao from "./component/kakaosignin.js";
import {
  useLocation,
  useNavigate,
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
} from "react-router-dom";


import { Cookies } from "react-cookie";
const cookies = new Cookies();

export const setCookie = (name, value, option) => {
  return cookies.set(name, value, { ...option });
};

export const getCookie = (name) => {
  return cookies.get(name);
};

function LoginForm({}) {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const [IsuserId, setIsuserId] = useState(true);
  const [Ispasswd, setIspasswd] = useState(true);

  const location = useLocation(); // 현재 위치 정보
  const navigate = useNavigate();

  const getGidFromSearch = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("gid");
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const gid = getGidFromSearch();
    setIsuserId(!!IsuserId);
    setIspasswd(!!Ispasswd);

    if (!userId) {
      alert("아이디를 입력해주세요");
      return;
    } else if (!password) {
      alert("비밀번호를 입력해주세요");
    }

    await handleLogin(userId, password, gid);
  };

  async function handleLogin(userId, password, gid) {
    try {
      //axios.defaults.withCredentials = true;
      const response = await axios.post(
        "https://dev-api.connectplay.kr:9443/api/users/signin",
        { userId, password, gid },
        {
          withCredentials:true
        }
      );
      
      const { uid, rurl } = response.data.data;
      let auth = response.headers.get('Authorization');
      console.log(response);
      console.log("성공");
      let jwt1 = getCookie('jwt');
      console.log(auth);
      console.log("=-=-=-=");      
      //alert("test");
      console.log(jwt1);
      if (jwt1) {
        setCookie("token", `${jwt1}`, {
            maxAge: 10800, 
            httpOnly: true, 
            domain: 'connectplay.kr', 
            path:'/',
            overwrite:true });
      }
      
      console.log(uid);
      console.log(rurl);
      //console.log(accessToken);
      window.location.href = `${rurl}?uid=${uid}`;
      //window.location.href = `//dev-race.connectplay.kr/`;
      //window.location.href = `/testbox`;
    } catch (error) {
      console.error("Login failed:", error);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <div>
        <div className="sign_text">로그인</div>
        <label htmlFor="userId">아이디</label>
        <input
          id="userId"
          type="text"
          value={userId}
          placeholder="아이디 입력"
          onChange={(e) => setUserId(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">비밀번호</label>
        <input
          id="password"
          type="password"
          value={password}
          placeholder="비밀번호 입력"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Link to="/signup" className="App-link sign_btn_text">
        회원가입
      </Link>
      <button type="submit">로그인</button>
      <SocialKakao />
    </form>
  );
}

export default LoginForm;