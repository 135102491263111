import React, { useEffect, useState } from "react";
import "./css/LoginForm.css";
import {
  useLocation,
  useNavigate,
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
} from "react-router-dom";

function Testbox({}) {
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
  
    const [IsuserId, setIsuserId] = useState(true);
    const [Ispasswd, setIspasswd] = useState(true);
  
    const location = useLocation(); // 현재 위치 정보
    const navigate = useNavigate();
  
    const getGidFromSearch = () => {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get("gid");
    };

    return (
       <div>
           테스트 페이지
       </div>
    ); 
}


export default Testbox;