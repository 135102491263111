import React from "react";
import { useLocation } from "react-router-dom";

function AuthHandler() {
  const location = useLocation();

  React.useEffect(() => {
    // URL에서 인증 코드(code) 추출
    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      console.log("인증 코드:", code);
      // 여기에서 인증 코드를 사용하여 서버에 액세스 토큰 요청 등의 추가 작업을 수행
    }
  }, [location]);

  return <div>인증 처리 중...</div>;
}

export default AuthHandler;
