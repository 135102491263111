import logo from "./logo.svg";
import "./css/App.css";
import SignUpForm from "./SignUp.js";
import LoginForm from "./SignIn.js";
import Testbox from "./Testbox.js";
import AuthHandler from "./component/AuthHandler.js";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";


function Home() {

  function handleExperienceClick() {

    const url =
      "http://dev-xzit.connectplay.kr/teacher?pin=150515&teamCount=10&levelUpScore=50";
    window.location.href = url;
  }


  return (
    <div className="App">
      <header className="App-header">
        <div>ConnectPlay 통합로그인</div>
        <Link to="/signin" className="App-link"><button>
          로그인
        </button></Link>
        <Link to="/signup" className="App-link"><button>
          회원가입
        </button></Link>
        <button onClick={handleExperienceClick} className="App-link">
          엑지트 체험하기
        </button>
      </header>
    </div>
  );
}

function App() {
  function SignUpBtn() {}

  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/signin" element={<LoginForm />} />
        <Route path="/testbox" element={<Testbox />} />        
        <Route path="/auth/kakao" element={<AuthHandler />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;